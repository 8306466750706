@font-face {
  font-family: poppinsBold;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppinsSemiBold;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: mulish;
  src: url(assets/fonts/Mulish-VariableFont_wght.ttf);
}

/* HERO SECTION (APRIL 2024) */

.recipe-section-container {
  padding: 40px;
  min-height: fit-content;
}

.top-img-container {
  position: relative;
  width: 100%;
}

.top-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1360 / 320;
  border-radius: 24px;
}

.top-img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
      0deg,
      rgba(36, 49, 62, 0.05),
      rgba(36, 49, 62, 0.05)
    ),
    linear-gradient(
      269.71deg,
      rgba(36, 49, 62, 0) 0.25%,
      rgba(36, 49, 62, 0.5) 99.76%
    );
  border-radius: 24px;
}

.top-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 40px;
}

.top-main-text {
  max-width: 1280px;
  font-weight: 700;
  color: #ffffff;
}

.top-subtext {
  font-family: mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
}

.line-break-recipes-tablet,
.line-break-recipes-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .top-subtext {
    font-size: 14px;
  }

  .top-main-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }

  .line-break-recipes-tablet {
    display: block;
  }

  .top-img-container {
    aspect-ratio: 688/240;
  }
}

@media screen and (max-width: 768px) {
  .recipe-section-container {
    padding: 24px;
  }

  .top-content {
    gap: 6px;
  }

  .top-main-text {
    font-family: poppinsBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .top-subtext {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .top-subtext {
    font-size: 14px;
  }

  .display-additional-text-mobile {
    display: none;
  }

  .line-break-recipes-mobile {
    display: block;
  }

  .top-img-container {
    aspect-ratio: 342/160;
  }
}

/* TITLE & FILTER BUTTTON (APRIL 2024) */

.recipe-title-container {
  color: #24313e;
  text-align: left;
  margin: 0 0 40px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.recipe-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recipe-nums {
  font-family: PoppinsSemiBold;
  color: #69727b;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.recipe-title-text {
  margin: 0;
}

.toggle-filters-button {
  border: 1px solid #e1e7ed;
  width: 48px;
  height: 48px;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toggle-filters-button img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1024px) {
  .toggle-filters-button {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .toggle-filters-button {
    display: flex;
  }
}

/* GRID ITEMS (APRIL 2024) */

.right-column {
  margin-left: 40px;
}

.thumb_image {
  width: 100%;
  aspect-ratio: 322/240;
  object-fit: cover;
}

.ais-Hits-item {
  width: 100%;
  padding: 0;
  box-shadow: none;
}

.ais-Hits-item a {
  width: 100%;
  padding: 0;
}

.ais-Hits-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px 24px;
  justify-content: center;
  align-items: flex-start;
}

.browse-grid-item {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.thumb_image_container {
  height: auto;
  border-radius: 12px;
  overflow: hidden;
}

.browse-grid-item img {
  transition: transform 0.5s ease; /* Smooth transition for zoom effect */
}

.browse-grid-item:hover img {
  transform: scale(1.3); /* Adjust scale value to control zoom level */
}

.browse-grid-item:hover {
  text-decoration: underline;
  text-decoration-color: #24313e;
}

.browse-grid-item p {
  color: #24313e;
  font-family: mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .right-column {
    margin-left: 0px;
  }

  .ais-Hits-list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 24px;
  }
}

@media screen and (max-width: 768px) {
  .right-column {
    margin-left: 0px;
  }
  .ais-Hits-list {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}

/* Pagination (April 2024) */

.ais-Pagination-list {
  margin-top: 40px;
  justify-content: center;
  border: none !important;
  background: none;
  box-shadow: none !important;
}

.ais-Pagination-link {
  background-color: none !important;
  border: none;
  background-image: none !important;
}

.ais-Pagination-item {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ais-Pagination-item a {
  width: 40px;
  height: 40px;
  background-color: none !important;
  border: none;
  box-shadow: none !important;
  border-radius: 100px;
  font-family: mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #24313e;
  box-sizing: border-box;
  padding: 0;
}

.ais-Pagination-item:hover {
  text-decoration: underline;
  font-weight: 700;
}

.ais-Pagination-item span,
.ais-Pagination-item a {
  background-color: none;
}

.ais-Pagination-item--selected {
  background-color: #f6f8fa;
  border: 1px solid #e1e7ed;
  border-radius: 100px;
  box-shadow: none !important;
}

.ais-Pagination-item--selected a {
  background-color: #f6f8fa;
  font-weight: 500 !important;
  border: 1px solid #e1e7ed;
  border-radius: 100px !important;
}

/* EMPTY RESULTS */

.empty-results-container {
  width: 100%;
  background: #f6f8fa;
  height: 152px;
  gap: 8px;
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-results-text {
  font-family: mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

/* SEARCH BOX (April 2024)*/

.ais-SearchBox {
  width: 100%;
  height: 48px;
  display: flex;

  .ais-SearchBox-form {
    width: 100%;

    .ais-SearchBox-input {
      box-shadow: none !important;
      font-family: Mulish, sans-serif !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      padding-left: 40px;
      border-radius: 8px;
      border: 1px solid #e1e7ed;
      color: #24313e;
      width: 100%;

      &::placeholder {
        font-family: Mulish, sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        color: #a3a8ae;
      }
    }

    &::before {
      /* don't show the looking-glass icon -it's getting distorted. There's some conflict with MaterialKit that
         I haven't tracked down yet. This overrides satellite-min.css */
      background: none;
    }
  }
}

.browse-search-container {
  padding: 10px;
  position: relative;
}

.browse-search-container input {
  width: 80%;       /* Optional: makes the input stretch to container width */
  padding: 4px 8px; /* Adds space inside the input */
  box-sizing: border-box;
}

.browse-flex-container {
  display: flex;
  gap: 0px;
}

.browse-search-magnify {
  position: absolute;
  left: 12px;
  top: 10.4px;
}

.toggle-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.only-my-recipes-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 322px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.filter-title {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.filter-label {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  display: flex;
  gap: 8px;
}

.custom-checkbox {
  position: relative;
  display: block;
  padding-left: 28px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  font-family: Mulish, sans-serif;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  background-image: url("assets/icons/checkbox-unchecked.svg");
}

.custom-checkbox input:checked ~ .checkmark {
  background-image: url("assets/icons/checkbox-checked.svg");
  background-size: cover;
  z-index: 1;
}

.toggle-filters-button {
  z-index: 10;
}

.nutritional-filter-container,
.category-filter-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.Container {
  align-items: flex-start;
}

.Search-header {
  display: grid;
  align-items: flex-start;
}

.Pagination {
  display: flex;
  justify-content: center;
}

.filter-layout {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media screen and (max-width: 1024px) {
  .left-column {
    width: 100%;
  }
  .browse-flex-container {
    gap: 40px;
  }
  .filter-layout {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
}

/* Lottie File */

.browse-Lottie-player {
  width: 80px;
  height: 80px;
}

.loading-item {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.loading-background {
  background: #f6f8fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 322/400;
  border-radius: 12px;
}

/* Browse Badges */

.browse-badge-container {
  height: 100%;
  position: absolute;
  z-index: 50;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px;
}

.browse-item-badge {
  background: #ffffff;
  height: 32px;
  width: fit-content;
  padding: 8px 16px;
  width: 100%;
  border-radius: 8px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #24313e;
  text-decoration: none;
}

/* Toggle Filters Container/Text */

.toggle-filters-container {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.toggle-filters-text {
  padding: 0;
  margin: 0;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;
  color: #a3a8ae;
}

@media screen and (max-width: 1024px) {
  .toggle-filters-container {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .browse-flex-container {
    gap: 24px;
  }
  .recipe-title-container {
    margin: 0 0 24px 0;
  }
}

.wrapped-img-container {
  height: 100%;
  width: 100%;
  aspect-ratio: 322/240;
  background: #f6f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapped-img-container img[src*=".svg"] {
  width: 160px;
  height: 160px;
}

/* Welcome Modal */

.welcome-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  z-index: 100;
}

.welcome-modal-title {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #24313e;
  border-bottom: 1px solid rgba(225, 231, 237, 1);
  padding: 0px 0px 24px 0px;
  gap: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.welcome-modal-title img {
  cursor: pointer;
}

.welcome-modal-subtitle {
  font-family: PoppinsSemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #24313e;
  padding: 0;
  margin: 0;
}

.welcome-modal-subtext-bold {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #24313e;
  padding: 0;
  margin: 0;
}

.welcome-modal-subtext {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #6a727b;
  padding: 0;
  margin: 0;
}

.welcome-modal-navigate-container {
  padding: 0px 0px 16px 0px;
  gap: 32px;
  display: flex;
}

.welcome-modal-btn {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #24313e;
  color: #ffffff;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.welcome-modal-navigate-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.welcome-modal-dots-container {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.welcome-modal-dots-container img {
  cursor: pointer;
}

.welcome-modal-navigate-container img {
  cursor: pointer;
}

.welcome-modal-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.welcome-modal-list-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.welcome-modal-subtext-alt {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.carousel-container {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
}

.carousel-container img {
  border-radius: 16px;
}

.welcome-carousel-img {
  width: 100% !important;
}

.welcome-modal-img {
  border-radius: 16px;
}

.carousel-caption {
  position: absolute;
  top: -40px;
}
